import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import tour1 from '../assets/images/tour01.jpg';
import tour2 from '../assets/images/tour02.jpg';
import tour3 from '../assets/images/tour03.jpg';
import tour4 from '../assets/images/tour04.jpg';
import tour5 from '../assets/images/tour05.jpg';
import tour6 from '../assets/images/tour06.jpg';
import tour7 from '../assets/images/tour07.jpg';
import tour8 from '../assets/images/tour08.jpg';
import tour9 from '../assets/images/tour09.jpg';
import tour10 from '../assets/images/tour10.jpg';
import tour11 from '../assets/images/tour11.jpg';
import tour11supp01 from '../assets/images/tour11supp01.png';
import tour12 from '../assets/images/tour12.jpg';
import tour13 from '../assets/images/tour13.jpg';

const sectionImages = {
    'Cultural Jeddah': tour1,
    'Cultural Jeddah (With Sobia)': tour2,
    'Tayyabat Museum & Enany Mosque': tour3,
    'Highlights of Jeddah': tour4,
    'Highlights of Jeddah with Airport drop off': tour5,
    'Lives of Saudi Women': tour6,
    'Jeddah Panoramic': tour7,
    'Beach Escape': tour8,
    'Bayadah Reef Experience (ALAMANA MARINE)': tour9,
    'Bayadah Reef Experience (REDSEA MARINE)': tour10,
    'Desert Camp Experience': tour11,
    'Cultural Jeddah (With Lunch)': tour12,
    'Cultural Jeddah (With Dinner)': tour13,
};


const ServiceProviderRFP = () => {
    const [sections, setSections] = useState(['t']);
    const [pricing, setPricing] = useState({});
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = localStorage.getItem('theme');
        return savedTheme === 'dark' || (!savedTheme && window.matchMedia('(prefers-color-scheme: dark)').matches);
    });

    const [showNotePage, setShowNotePage] = useState(true);
    const [maximizedImage, setMaximizedImage] = useState(null);
    const [selectedSection, setSelectedSection] = useState(0); // Track the current section index
    const [otherTier, setOtherTier] = useState({});

    const [confirmation, setConfirmation] = useState(null);
    const [success, setSuccess] = useState(false);
    const [finished, setFinished] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [warning, setWarning] = useState(null);

    const { port } = useParams();

    // Add this new state for tracking expanded ports
    const [expandedPorts, setExpandedPorts] = useState(new Set());

    // Add this new state for tracking bidding status and ID
    const [isBiddingOpen, setIsBiddingOpen] = useState(false);
    const [biddingId, setBiddingId] = useState(null);

    // Add a new state to track if bidding status check is complete
    const [isBiddingStatusChecked, setIsBiddingStatusChecked] = useState(false);

    // Add this new state near the other state declarations
    const [submittedData, setSubmittedData] = useState({});

    // Add this new state near the other state declarations
    const [isFetchingBidding, setIsFetchingBidding] = useState(false);

    // Add this helper function to group tours by port
    function groupToursByPort(tours) {
        return tours.reduce((acc, tour) => {
            if (!acc[tour.port]) {
                acc[tour.port] = [];
            }
            acc[tour.port].push(tour);
            return acc;
        }, {});
    }

    // Modify the bidding status check useEffect
    useEffect(() => {
        const checkBiddingStatus = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('No authentication token found');
                }

                const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/biddingStatus`, {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch bidding status');
                }
                const data = await response.json();
                console.log(data);
                const isOpen = data.status === 'Started';
                setIsBiddingOpen(isOpen);
                setBiddingId(data.id);

                if (!isOpen) {
                    setWarning('Bidding is currently closed. Please try again later.');
                }
            } catch (error) {
                console.error("Error checking bidding status:", error);
                setError(error);
            } finally {
                setLoading(false);
                setIsBiddingStatusChecked(true);
            }
        };

        checkBiddingStatus();
    }, []);

    // Modify the fetchRFPUserTours useEffect to depend on bidding status
    useEffect(() => {
        // Only proceed if bidding status has been checked
        if (!isBiddingStatusChecked) {
            return;
        }

        // If bidding is not open, don't fetch tours
        if (!isBiddingOpen) {
            return;
        }

        const fetchRFPUserTours = async () => {
            setLoading(true);
            try {
                const userEmail = JSON.parse(localStorage.getItem('user')).email;
                const userTours = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getRFPUserTours/${userEmail}`);
                if (!userTours.ok) {
                    throw new Error('Failed to fetch lookups');
                }
                const userToursData = await userTours.json();

                if (userToursData.length === 0) {
                    setWarning('Nothing to submit. If this is a mistake, please contact your account manager.');
                    setLoading(false);
                    return;
                }

                if (userToursData.length > 0) {
                    localStorage.setItem('started', 'true');
                }

                const toursWithDetails = await Promise.all(userToursData.map(async userTour => {
                    const tourDetails = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getRFPTour/${userTour.TourLookupId}`);
                    if (!tourDetails.ok) {
                        throw new Error('Failed to fetch lookups');
                    }
                    const details = await tourDetails.json();
                    return details;
                }));

                const tourTiers = await Promise.all(toursWithDetails.map(async tour => {
                    const tourTiers = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getRDPTiers/${tour.id}`);
                    if (!tourTiers.ok) {
                        throw new Error('Failed to fetch lookups');
                    }
                    const tiers = await tourTiers.json();
                    return { id: tour.id, title: tour.Title, port: tour.Port, image: sectionImages[tour.Title], tiers: tiers.sort((a, b) => a.Title.localeCompare(b.Title)).map(tier => ({ id: tier.id, title: tier.Title })), season: tour.Season, code: tour.Code, document: tour.document };
                }));
                console.log(tourTiers);

                setSections(tourTiers);
                setWarning(null);
            } catch (error) {
                console.error("Error fetching tours:", error);
                setError(error);
            }
            setLoading(false);
        };

        fetchRFPUserTours();
    }, [isBiddingStatusChecked, isBiddingOpen]); // Add dependencies

    useEffect(() => {
        document.documentElement.classList.toggle('dark', isDarkMode);
        localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    }, [isDarkMode]);

    useEffect(() => {
        if (maximizedImage) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [maximizedImage]);

    useEffect(() => {
        if (selectedSection === sections.length) {
            if (sections.length > 1) {
                setFinished(true);
            }
            else {
                setWarning('Nothing to submit. If this is a mistake, please contact your account manager.');
            }
        }
        else {
            setFinished(false);
            setWarning(null);
        }
    }, [selectedSection, sections.length]);

    // Modify the fetchSubmittedData function in the useEffect
    useEffect(() => {
        const fetchSubmittedData = async () => {
            // Return early if no section id or bidding id
            if (!sections[selectedSection]?.id || !biddingId) {
                return;
            }

            setIsFetchingBidding(true); // Show loading spinner
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('No authentication token found');
                }

                const userEmail = JSON.parse(localStorage.getItem('user')).email;
                const response = await fetch(
                    `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getSubmittedBidding/${userEmail}/${sections[selectedSection].id}/${biddingId}`,
                    {
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (response.ok) {
                    const data = await response.json();
                    setSubmittedData(prev => ({
                        ...prev,
                        [sections[selectedSection].id]: data
                    }));

                    // Pre-fill the pricing state with submitted data
                    const pricingData = {};
                    data.tiers.forEach(tier => {
                        pricingData[tier.tierId] = tier.pricing;
                    });
                    setPricing(prev => ({
                        ...prev,
                        [sections[selectedSection].title]: pricingData
                    }));
                }
            } catch (error) {
                console.error("Error fetching submitted data:", error);
            } finally {
                setIsFetchingBidding(false); // Hide loading spinner
            }
        };

        if (isBiddingStatusChecked && biddingId) {
            fetchSubmittedData();
        }
    }, [selectedSection, sections, biddingId, isBiddingStatusChecked]);

    const handlePriceChange = (section, tier, type, value) => {
        setPricing(prev => ({
            ...prev,
            [section]: {
                ...prev[section],
                [tier]: {
                    ...prev[section]?.[tier],
                    [type]: value
                }
            }
        }));
    };

    const handleOtherTierChange = (section, value) => {
        setOtherTier(prev => ({
            ...prev,
            [section]: value
        }));

        // Remove the 'Other' tier and add the new custom tier
        setPricing(prev => {
            const sectionPricing = { ...prev[section] };
            delete sectionPricing['Other'];
            return {
                ...prev,
                [section]: {
                    ...sectionPricing,
                    [value]: {
                        adultRate: '',
                        childRate: '',
                        infantRate: ''
                    }
                }
            };
        });
    };

    const handleSubmit = async (event, section) => {
        event.preventDefault();

        if (!isBiddingOpen) {
            setError(new Error('Bidding is currently closed'));
            return;
        }

        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found in local storage');
            }
            const user = JSON.parse(localStorage.getItem('user'));
            const data = Object.entries(pricing[section.title] || {}).map(([tier, pricing]) => ({
                tier,
                pricing
            }));

            const req = {
                tour: section.id,
                pricing: data,
                port: section.port,
                user: user,
                season: section.season,
                biddingRound: biddingId // Add the bidding round ID
            };

            const result = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/submitRFP`, {
                method: 'POST',
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(req)
            });

            if (!result.ok) {
                throw new Error('Failed to submit RFP');
            }

            setLoading(false);
            setSuccess(true);
            setSelectedSection(prev => prev + 1);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        } catch (error) {
            console.error("Error submitting RFP:", error);
            setError(error);
            setLoading(false);
        }
    };

    const handleSkip = () => {
        setConfirmation({
            title: 'Are you sure you want to skip this tour?',
            message: 'You will not be able to make changes to this tour later.',
            onConfirm: () => {
                setSelectedSection(prev => prev + 1);
                setConfirmation(null);
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        });
    };

    const handleImageClick = (image) => {
        setMaximizedImage(image);
    };

    const handleCloseMaximizedImage = () => {
        setMaximizedImage(null);
    };

    if (showNotePage) {
        return (
            <>
                {loading && (
                    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
                    </div>
                )}
                {finished && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" id="finished-modal">
                        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
                            <div className="mt-3 text-center">
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mt-5">All Forms Submitted!</h3>
                                <div className="mt-2 px-7 py-3">
                                    <p className="text-sm text-gray-500 dark:text-gray-400">
                                        You have successfully submitted all forms. You may now close this page.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {warning && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" id="warning-modal">
                        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
                            <div className="mt-3 text-center">
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                                    <svg className="h-6 w-6 text-yellow-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                    </svg>
                                </div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mt-5">Warning</h3>
                                <div className="mt-2 px-7 py-3">
                                    <p className="text-sm text-gray-500 dark:text-gray-400">
                                        {warning}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="max-w-2xl mx-auto p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md h-full">
                    <h1 className="text-3xl font-bold mb-6 text-center text-gray-900 dark:text-white">Important Note</h1>
                    <div className="space-y-4">
                        <p className="text-gray-700 dark:text-gray-300">
                            Please carefully review the following instructions before proceeding:
                        </p>
                        <ol className="list-decimal list-inside space-y-2 text-gray-700 dark:text-gray-300">
                            <li>Fill out all required fields in each form tour.</li>
                            <li>Double-check your entries for accuracy before submitting.</li>
                            <li>Submit each tour individually using the "Submit Pricing" button.</li>
                            <li>Once all tours are submitted, the link will be locked and you won't be able to make changes.</li>
                        </ol>
                        <p className="text-gray-700 dark:text-gray-300 font-semibold">
                            Please note: After submitting all forms, this link will be locked and you will not be able to make further changes.
                        </p>
                    </div>
                    <button
                        onClick={() => setShowNotePage(false)}
                        className="mt-8 w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
                    >
                        I Understand, Continue to Forms
                    </button>
                </div>
            </>
        );
    }

    return (
        <>
            {loading && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
                </div>
            )}
            {error && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" id="my-modal">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
                        <div className="mt-3 text-center">
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                </svg>
                            </div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mt-5">Error</h3>
                            <div className="mt-2 px-7 py-3">
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                    {error.message || "An error occurred. Please try again."}
                                </p>
                            </div>
                            <div className="items-center px-4 py-3">
                                <button
                                    id="ok-btn"
                                    className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
                                    onClick={() => setError(null)}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {success && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="success-modal" style={{ zIndex: 51 }}>
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
                        <div className="mt-3 text-center">
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mt-5">Success!</h3>
                            <div className="mt-2 px-7 py-3">
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                    Your pricing has been successfully submitted.
                                </p>
                            </div>
                            <div className="items-center px-4 py-3">
                                <button
                                    id="ok-btn"
                                    className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
                                    onClick={() => setSuccess(false)}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {finished && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" id="finished-modal">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
                        <div className="mt-3 text-center">
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mt-5">All Forms Submitted!</h3>
                            <div className="mt-2 px-7 py-3">
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                    You have successfully submitted all forms. You may now close this page.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {confirmation && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" id="confirmation-modal">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
                        <div className="mt-3 text-center">
                            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">{confirmation.title}</h3>
                            <div className="mt-2 px-7 py-3">
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                    {confirmation.message}
                                </p>
                            </div>
                            <div className="items-center px-4 py-3">
                                <button
                                    className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 mb-2"
                                    onClick={confirmation.onConfirm}
                                >
                                    Confirm
                                </button>
                                <button
                                    className="px-4 py-2 bg-gray-300 text-gray-800 text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
                                    onClick={() => setConfirmation(null)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className={`container mx-auto px-4 bg-white dark:bg-gray-800 text-gray-900 dark:text-white flex ${maximizedImage ? 'overflow-hidden' : ''}`}>
                {/* Index on the left side */}
                <div className="w-1/4 pr-4 pt-8 sticky top-0 h-screen overflow-auto">
                    <h2 className="text-2xl font-bold mb-4">Tours by Port</h2>
                    <ol className="relative border-l border-gray-200 dark:border-gray-700 ml-1">
                        {Object.entries(groupToursByPort(sections)).map(([port, portTours]) => (
                            <li key={port} className="mb-4 ml-4">
                                <div className="flex items-center">
                                    <button
                                        onClick={() => {
                                            setExpandedPorts(prev => {
                                                const newSet = new Set(prev);
                                                if (newSet.has(port)) {
                                                    newSet.delete(port);
                                                } else {
                                                    newSet.add(port);
                                                }
                                                return newSet;
                                            });
                                        }}
                                        className="flex items-center text-left hover:text-blue-500 transition duration-300 w-full"
                                    >
                                        <svg
                                            className={`w-4 h-4 mr-2 transform transition-transform ${expandedPorts.has(port) ? 'rotate-90' : ''
                                                }`}
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M9 5l7 7-7 7"
                                            />
                                        </svg>
                                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                            {port}
                                        </h3>
                                    </button>
                                </div>

                                {expandedPorts.has(port) && (
                                    <ul className="mt-2 ml-6">
                                        {portTours.map((tour, index) => (
                                            <li key={index} className="mb-2 relative">
                                                <div className={`absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-[1.67rem] border border-white dark:border-gray-900 dark:bg-gray-700 ${selectedSection === sections.indexOf(tour)
                                                    ? 'bg-blue-400 border-blue-500 dark:bg-blue-500 dark:border-blue-500'
                                                    : ''
                                                    }`}></div>
                                                <button
                                                    onClick={() => setSelectedSection(sections.indexOf(tour))}
                                                    className={`text-left hover:text-blue-500 transition duration-300 ${selectedSection === sections.indexOf(tour)
                                                        ? 'text-blue-500 font-semibold'
                                                        : ''
                                                        }`}
                                                >
                                                    <span className="text-sm">{tour.code}</span>
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ol>
                </div>

                {/* Main content on the right side */}
                <div className="w-3/4 pl-4">
                    <h1 className="text-4xl font-bold text-center mb-8 pt-8">
                        {port} Shore Excursion Details
                    </h1>
                    {isFetchingBidding && (
                        <div className="flex justify-center items-center my-8">
                            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
                        </div>
                    )}
                    {!isBiddingOpen && (
                        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
                            <p className="font-bold">Bidding Closed</p>
                            <p>The bidding round is currently closed. You cannot submit pricing at this time.</p>
                        </div>
                    )}
                    {!isFetchingBidding && sections.map((section, index) => (
                        <form key={index}
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (!isBiddingOpen) {
                                    setError(new Error('Bidding is currently closed'));
                                    return;
                                }
                                setConfirmation({
                                    title: 'Are you sure you want to submit this tour?',
                                    message: 'You will not be able to make changes to this tour later.',
                                    onConfirm: () => {
                                        handleSubmit(e, section);
                                        setConfirmation(null);
                                    }
                                });
                            }}
                            className={`mb-8 ${selectedSection === index ? '' : 'hidden'}`}
                            name={section.title}
                            id={`section-${index}`}>
                            <div className="bg-gray-100 dark:bg-gray-700 shadow-md rounded-lg p-6 mb-4">
                                <h2 className="text-2xl font-semibold mb-4">
                                    {section.title}
                                </h2>
                                <div className="flex flex-wrap mb-6">
                                    {section.document?.name?.toLowerCase().endsWith('.pdf') ? (
                                        <iframe
                                            src={`data:application/pdf;base64,${section.document.content}`}
                                            className="w-full h-96 pr-2"
                                            title={`${section.title} PDF`}
                                        />
                                    ) : (
                                        <img
                                            src={section.document ? `data:image/jpeg;base64,${section.document.content}` : section.image}
                                            alt={`${section.title} PDF`}
                                            className="w-full pr-2 cursor-pointer"
                                            onClick={() => handleImageClick(section.document ? `data:image/jpeg;base64,${section.document.content}` : section.image)}
                                        />
                                    )}
                                    {section.supportImage && (
                                        <img
                                            src={section.supportImage}
                                            alt={`${section.title} Support Image`}
                                            className="w-26 pl-2 mt-4 md:mt-0 cursor-pointer"
                                            onClick={() => handleImageClick(section.supportImage)}
                                        />
                                    )}
                                </div>
                                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-5 2xl:grid-cols-7 gap-6">
                                    {section.tiers.map((tier, tierIndex) => {
                                        const isSubmitted = submittedData[section.id];
                                        return (
                                            <div key={tierIndex} className="space-y-4">
                                                <h3 className="text-md font-medium">
                                                    {tier.title === 'Other' ? (
                                                        <input
                                                            type="text"
                                                            placeholder="Other (please state)"
                                                            className={`w-full p-2 border border-gray-300 dark:border-gray-600 rounded 
                                                                ${isSubmitted ? 'bg-gray-100 dark:bg-gray-700' : 'bg-white dark:bg-gray-600'} 
                                                                text-gray-900 dark:text-white`}
                                                            onChange={(e) => handleOtherTierChange(section.title, e.target.value)}
                                                            value={otherTier[section.title] || ''}
                                                            disabled={isSubmitted}
                                                        />
                                                    ) : (
                                                        <>
                                                            <p>{tier.title}</p>
                                                            <p className="text-red-500 text-xs">(VAT Excluded, الاسعار بدون ضريبة القيمة المضافة)</p>
                                                        </>
                                                    )}
                                                </h3>
                                                <label>
                                                    ADULT
                                                    <br />
                                                    (12+ years old)
                                                </label>
                                                <input
                                                    type="number"
                                                    placeholder="Adult Rate"
                                                    min="0"
                                                    step="1"
                                                    className={`w-full p-2 border border-gray-300 dark:border-gray-600 rounded 
                                                        ${isSubmitted ? 'bg-gray-100 dark:bg-gray-700' : 'bg-white dark:bg-gray-600'} 
                                                        text-gray-900 dark:text-white`}
                                                    onChange={(e) => handlePriceChange(section.title, tier.title === 'Other' ? otherTier[section.title] : tier.id, 'adultRate', e.target.value)}
                                                    value={pricing[section.title]?.[tier.id]?.adultRate || ''}
                                                    required={tier.title !== 'Other'}
                                                    disabled={isSubmitted}
                                                />
                                                <label>
                                                    CHILD
                                                    <br />
                                                    (3-11.99 years old)
                                                </label>
                                                <input
                                                    type="number"
                                                    placeholder="Child Rate"
                                                    min="0"
                                                    step="1"
                                                    className={`w-full p-2 border border-gray-300 dark:border-gray-600 rounded 
                                                        ${isSubmitted ? 'bg-gray-100 dark:bg-gray-700' : 'bg-white dark:bg-gray-600'} 
                                                        text-gray-900 dark:text-white`}
                                                    onChange={(e) => handlePriceChange(section.title, tier.title === 'Other' ? otherTier[section.title] : tier.id, 'childRate', e.target.value)}
                                                    value={pricing[section.title]?.[tier.id]?.childRate || ''}
                                                    required={tier.title !== 'Other'}
                                                    disabled={isSubmitted}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="flex justify-between">
                                <button
                                    type="submit"
                                    className={`w-1/2 py-3 px-4 rounded-lg transition duration-300 
                                        ${!isBiddingOpen || submittedData[section.id]
                                            ? 'bg-gray-400 cursor-not-allowed text-gray-200'
                                            : 'bg-blue-600 hover:bg-blue-700 text-white'
                                        }`}
                                    disabled={!isBiddingOpen || submittedData[section.id]}
                                >
                                    {submittedData[section.id] ? 'Already Submitted' : `Submit Pricing for ${section.title}`}
                                </button>
                                <button
                                    type="button"
                                    onClick={handleSkip}
                                    className={`w-1/2 py-3 px-4 rounded-lg transition duration-300 ml-4
                                        ${submittedData[section.id]
                                            ? 'bg-gray-400 cursor-not-allowed text-gray-200'
                                            : 'bg-gray-600 hover:bg-gray-700 text-white'
                                        }`}
                                    disabled={submittedData[section.id]}
                                >
                                    Skip
                                </button>
                            </div>
                        </form>
                    ))}
                </div>
                {maximizedImage && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 overflow-auto">
                        <div className="relative max-w-full max-h-full" style={{ marginTop: '101px' }}>
                            <div className="overflow-auto">
                                <img src={maximizedImage} alt="Maximized" className="max-w-full max-h-full" />
                            </div>
                            <button
                                onClick={handleCloseMaximizedImage}
                                className="absolute top-1 right-1 bg-red-500 hover:bg-red-600 text-white font-bold px-6 py-3 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
                                style={{ top: '-50px' }}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ServiceProviderRFP;
