import { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export function ShoreExcursionAdmin() {
    const [vendors, setVendors] = useState([]);
    const [tours, setTours] = useState([]);
    const [submissions, setSubmissions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedVendors, setSelectedVendors] = useState([]);
    const [selectedTours, setSelectedTours] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [expandedAssignments, setExpandedAssignments] = useState(new Set());
    const [expandedPorts, setExpandedPorts] = useState(new Set());
    const [vendorsLoading, setVendorsLoading] = useState(false);
    const [toursLoading, setToursLoading] = useState(false);
    const [submissionsLoading, setSubmissionsLoading] = useState(false);
    const [assignmentsLoading, setAssignmentsLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [assignedVendorsLoading, setAssignedVendorsLoading] = useState(false);
    const [biddingStatus, setBiddingStatus] = useState('Closed');
    const [biddingStatusLoading, setBiddingStatusLoading] = useState(false);
    const [biddingActionLoading, setBiddingActionLoading] = useState(false);
    const [submittedBiddings, setSubmittedBiddings] = useState([]);
    const [submittedBiddingsLoading, setSubmittedBiddingsLoading] = useState(false);
    const [deletingVendors, setDeletingVendors] = useState(new Set());
    const [bulkDeletingVendor, setBulkDeletingVendor] = useState(null);
    const [removeAllConfirmation, setRemoveAllConfirmation] = useState({ show: false, vendor: null });
    const [modalRemoveLoading, setModalRemoveLoading] = useState(false);
    const [newTour, setNewTour] = useState({ title: '', port: '', description: '' });
    const [selectedFile, setSelectedFile] = useState(null);
    const [isAddingTour, setIsAddingTour] = useState(false);
    const [editingTour, setEditingTour] = useState(null);
    const [isEditingTour, setIsEditingTour] = useState(false);

    useEffect(() => {
        const fetchInitialData = async () => {
            setLoading(true);
            try {
                // Get token from localStorage
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('No authentication token found');
                }

                // Fetch vendors with authorization header
                setVendorsLoading(true);
                const vendorsResponse = await fetch(
                    `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/tour-providers`,
                    {
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (!vendorsResponse.ok) {
                    throw new Error('Failed to fetch vendors');
                }

                const vendorsData = await vendorsResponse.json();
                setVendors(vendorsData);
                setVendorsLoading(false);

                // Fetch tours
                setToursLoading(true);
                const toursResponse = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getRFPTours`, {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                });

                if (!toursResponse.ok) {
                    throw new Error('Failed to fetch tours');
                }

                const toursData = await toursResponse.json();
                setTours(toursData);
                setToursLoading(false);

                // Fetch submissions
                // const submissionsResponse = await fetch('/api/submissions');
                // const submissionsData = await submissionsResponse.json();
                setSubmissions([
                    {
                        id: 1,
                        vendorName: 'Vendor A',
                        tourName: 'Cultural Jeddah',
                        submittedAt: '2024-03-20',
                        status: 'completed',
                        pricing: {
                            adult: 100,
                            child: 50,
                            infant: 0
                        }
                    },
                ]);

                // Fetch assigned vendors
                setAssignedVendorsLoading(true);
                const assignedResponse = await fetch(
                    `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getAssignedVendors`,
                    {
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (!assignedResponse.ok) {
                    throw new Error('Failed to fetch assigned vendors');
                }

                const assignedData = await assignedResponse.json();

                const groupedAssignments = assignedData.reduce((acc, item) => {
                    const existingTour = acc.find(t => t.tourId === item.tourId);
                    if (existingTour) {
                        existingTour.assignedVendors.push({
                            id: item.id,
                            email: item.email,
                            submitted: item.submitted
                        });
                    } else {
                        acc.push({
                            id: item.tourId,
                            tourName: item.tourName,
                            tourId: item.tourId,
                            assignedVendors: [{
                                id: item.id,
                                email: item.email,
                                submitted: item.submitted
                            }]
                        });
                    }
                    return acc;
                }, []);

                // Then, add ports after tours are loaded
                const assignmentsWithPorts = groupedAssignments.map(assignment => ({
                    ...assignment,
                    port: toursData.find(t => t.id === assignment.tourId)?.port || 'Unknown',
                    code: toursData.find(t => t.id === assignment.tourId)?.code || 'Unknown'
                }));

                setAssignments(assignmentsWithPorts);
                setAssignedVendorsLoading(false);

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInitialData();
    }, []);

    useEffect(() => {
        if (assignments.length > 0 && tours.length > 0) {
            const updatedAssignments = assignments.map(assignment => ({
                ...assignment,
                port: tours.find(t => t.id === assignment.tourId)?.port || 'Unknown',
                code: tours.find(t => t.id === assignment.tourId)?.code || 'Unknown'
            }));
            setAssignments(updatedAssignments);
        }
    }, [tours]);

    // Add this new useEffect to fetch bidding status
    useEffect(() => {
        const fetchBiddingStatus = async () => {
            setBiddingStatusLoading(true);
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('No authentication token found');
                }

                const response = await fetch(
                    `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/biddingStatus`,
                    {
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (!response.ok) {
                    throw new Error('Failed to fetch bidding status');
                }

                const data = await response.json();
                setBiddingStatus(data.status);
            } catch (error) {
                console.error('Error fetching bidding status:', error);
            } finally {
                setBiddingStatusLoading(false);
            }
        };

        fetchBiddingStatus();
    }, []);

    useEffect(() => {
        const fetchSubmittedBiddings = async () => {
            setSubmittedBiddingsLoading(true);
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('No authentication token found');
                }

                const response = await fetch(
                    `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getSubmittedBiddings`,
                    {
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (!response.ok) {
                    throw new Error('Failed to fetch submitted biddings');
                }

                const data = await response.json();
                setSubmittedBiddings(data);
            } catch (error) {
                console.error('Error fetching submitted biddings:', error);
            } finally {
                setSubmittedBiddingsLoading(false);
            }
        };

        fetchSubmittedBiddings();
    }, []);

    const handleVendorSelection = (vendor) => {
        setSelectedVendors(prev =>
            prev.includes(vendor.email)
                ? prev.filter(email => email !== vendor.email)
                : [...prev, vendor.email]
        );
    };

    const handleTourSelection = (tourId) => {
        setSelectedTours(prev =>
            prev.includes(tourId)
                ? prev.filter(id => id !== tourId)
                : [...prev, tourId]
        );
    };

    const handleAssignVendors = async () => {
        try {
            setAssignmentsLoading(true);
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const assignments = selectedVendors.flatMap(email =>
                selectedTours.map(tourId => ({ email, tourId }))
            );

            await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/assignRFPTours`, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ assignments }),
            });

            setShowConfirmation(true);
            setSelectedVendors([]);
            setSelectedTours([]);
        } catch (error) {
            console.error('Error assigning vendors:', error);
        } finally {
            setAssignmentsLoading(false);
        }
    };

    // Replace startNewBiddingRound with these two functions
    const startBidding = async () => {
        setBiddingActionLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(
                `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/startBidding`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Failed to start bidding');
            }

            setBiddingStatus('Started');
        } catch (error) {
            console.error('Error starting bidding:', error);
        } finally {
            setBiddingActionLoading(false);
        }
    };

    const closeBidding = async () => {
        setBiddingActionLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(
                `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/closeBidding`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Failed to close bidding');
            }

            setBiddingStatus('Closed');
        } catch (error) {
            console.error('Error closing bidding:', error);
        } finally {
            setBiddingActionLoading(false);
        }
    };

    // Add clear handlers
    const clearVendors = () => {
        setSelectedVendors([]);
    };

    const clearTours = () => {
        setSelectedTours([]);
    };

    // Add select all handlers
    const handleSelectAllVendors = () => {
        if (selectedVendors.length === vendors.length) {
            setSelectedVendors([]);
        } else {
            setSelectedVendors(vendors.map(v => v.email));
        }
    };

    const handleSelectAllTours = () => {
        if (selectedTours.length === tours.length) {
            setSelectedTours([]);
        } else {
            setSelectedTours(tours.map(t => t.id));
        }
    };

    // Add handler for toggling expansion
    const toggleExpanded = (assignmentId) => {
        setExpandedAssignments(prev => {
            const newSet = new Set(prev);
            if (newSet.has(assignmentId)) {
                newSet.delete(assignmentId);
            } else {
                newSet.add(assignmentId);
            }
            return newSet;
        });
    };

    // Update the handleRemoveVendor function
    const handleRemoveVendor = async (tourId, vendor) => {
        try {
            setDeletingVendors(prev => new Set([...prev, vendor.email]));
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(
                `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/removeAssignedVendor`,
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: vendor.email,
                        tourId: tourId
                    }),
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to remove vendor');
            }

            // Update assignments locally after successful removal
            setAssignments(prev => prev.map(assignment => {
                if (assignment.id === tourId) {
                    return {
                        ...assignment,
                        assignedVendors: assignment.assignedVendors.filter(v => v.email !== vendor.email)
                    };
                }
                return assignment;
            }));
        } catch (error) {
            console.error('Error removing vendor:', error);
            alert(error.message);
        } finally {
            setDeletingVendors(prev => {
                const newSet = new Set(prev);
                newSet.delete(vendor.email);
                return newSet;
            });
        }
    };

    // Add handler for toggling port expansion
    const togglePortExpanded = (port) => {
        setExpandedPorts(prev => {
            const newSet = new Set(prev);
            if (newSet.has(port)) {
                newSet.delete(port);
            } else {
                newSet.add(port);
            }
            return newSet;
        });
    };

    // Update the handleRemoveVendorFromAll function to show confirmation first
    const handleRemoveVendorFromAll = async (vendor) => {
        setRemoveAllConfirmation({ show: true, vendor });
    };

    // Add new function to handle the actual removal after confirmation
    const confirmRemoveVendorFromAll = async () => {
        const vendor = removeAllConfirmation.vendor;
        setModalRemoveLoading(true);
        try {
            setBulkDeletingVendor(vendor.email);
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(
                `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/removeVendorFromAllTours`,
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: vendor.email
                    }),
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to remove vendor from all tours');
            }

            // Update assignments locally after successful removal
            setAssignments(prev => prev.map(assignment => ({
                ...assignment,
                assignedVendors: assignment.assignedVendors.filter(v => v.email !== vendor.email)
            })));

        } catch (error) {
            console.error('Error removing vendor from all tours:', error);
            alert(error.message);
        } finally {
            setBulkDeletingVendor(null);
            setModalRemoveLoading(false);
            setRemoveAllConfirmation({ show: false, vendor: null });
            window.location.reload();
        }
    };

    // Add Confirmation Modal Component
    const ConfirmationModal = () => (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
                <div className="mt-3 text-center">
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                        <svg
                            className="h-6 w-6 text-green-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            />
                        </svg>
                    </div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mt-5">
                        Assignment Successful!
                    </h3>
                    <div className="mt-2 px-7 py-3">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                            The vendors have been successfully assigned to the selected tours.
                        </p>
                    </div>
                    <div className="items-center px-4 py-3">
                        <button
                            onClick={() => window.location.reload()}
                            className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
                        >
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    // Add Loading Spinner Component
    const LoadingSpinner = () => (
        <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500"></div>
        </div>
    );

    // Add new RemoveAllConfirmationModal component
    const RemoveAllConfirmationModal = () => (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
                <div className="mt-3 text-center">
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                        <svg
                            className="h-6 w-6 text-red-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                        </svg>
                    </div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mt-5">
                        Remove Vendor from All Tours
                    </h3>
                    <div className="mt-2 px-7 py-3">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                            Are you sure you want to remove {removeAllConfirmation.vendor?.name || removeAllConfirmation.vendor?.email} from all tours? This action cannot be undone.
                        </p>
                    </div>
                    <div className="flex justify-center space-x-4 px-4 py-3">
                        <button
                            onClick={() => setRemoveAllConfirmation({ show: false, vendor: null })}
                            disabled={modalRemoveLoading}
                            className="px-4 py-2 bg-gray-300 text-gray-700 text-base font-medium rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 disabled:opacity-50"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={confirmRemoveVendorFromAll}
                            disabled={modalRemoveLoading}
                            className="px-4 py-2 bg-red-600 text-white text-base font-medium rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300 disabled:opacity-50 min-w-[100px]"
                        >
                            {modalRemoveLoading ? (
                                <div className="flex items-center justify-center space-x-2">
                                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    <span>Removing...</span>
                                </div>
                            ) : (
                                'Remove'
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleAddTour = async (e) => {
        e.preventDefault();
        setIsAddingTour(true);

        try {
            const formData = new FormData();
            formData.append('title', newTour.title);
            formData.append('port', newTour.port);
            formData.append('code', newTour.code);
            if (selectedFile) {
                formData.append('document', selectedFile);
            }

            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/createTour`, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to create tour');
            }

            // Reset form and refresh tours
            setNewTour({ title: '', port: '', code: '' });
            setSelectedFile(null);
            // Refresh tours list
            await fetchTours();
        } catch (error) {
            console.error('Error adding tour:', error);
            alert('Failed to add tour');
        } finally {
            setIsAddingTour(false);
        }
    };

    const handleEditTour = (tour) => {
        setEditingTour(tour);
    };

    const handleDeleteTour = async (tourId) => {
        if (!window.confirm('Are you sure you want to delete this tour?')) {
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/tours/${tourId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete tour');
            }

            // Refresh tours list
            await fetchTours();
        } catch (error) {
            console.error('Error deleting tour:', error);
            alert('Failed to delete tour');
        }
    };

    // Add fetchTours function
    const fetchTours = async () => {
        setToursLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getRFPTours`, {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch tours');
            }

            const data = await response.json();
            setTours(data);
        } catch (error) {
            console.error('Error fetching tours:', error);
        } finally {
            setToursLoading(false);
        }
    };

    // Add this new EditTourModal component near other modal components
    const EditTourModal = () => {
        const [editForm, setEditForm] = useState({
            title: editingTour?.name || '',
            port: editingTour?.port || '',
            code: editingTour?.code || ''
        });
        const [editFile, setEditFile] = useState(null);

        useEffect(() => {
            if (editingTour) {
                setEditForm({
                    title: editingTour.name,
                    port: editingTour.port,
                    code: editingTour.code
                });
            }
        }, [editingTour]);

        const handleEditSubmit = async (e) => {
            e.preventDefault();
            setIsEditingTour(true);

            try {
                const token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append('title', editForm.title);
                formData.append('port', editForm.port);
                formData.append('code', editForm.code);
                if (editFile) {
                    formData.append('document', editFile);
                }

                const response = await fetch(
                    `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/updateTour/${editingTour.id}`,
                    {
                        method: 'PUT',
                        headers: {
                            'Authorization': token,
                        },
                        body: formData,
                    }
                );

                if (!response.ok) {
                    throw new Error('Failed to update tour');
                }

                // Refresh tours list
                await fetchTours();
                setEditingTour(null);
            } catch (error) {
                console.error('Error updating tour:', error);
                alert('Failed to update tour');
            } finally {
                setIsEditingTour(false);
            }
        };

        return (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
                <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
                    <div className="mt-3">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                            Edit Tour
                        </h3>
                        <form onSubmit={handleEditSubmit} className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                    Tour Name
                                </label>
                                <input
                                    type="text"
                                    value={editForm.title}
                                    onChange={(e) => setEditForm({ ...editForm, title: e.target.value })}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                    Port
                                </label>
                                <select
                                    value={editForm.port}
                                    onChange={(e) => setEditForm({ ...editForm, port: e.target.value })}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                                    required
                                >
                                    <option value="Jeddah">Jeddah</option>
                                    <option value="Dammam">Dammam</option>
                                    <option value="Yanbu">Yanbu</option>
                                    <option value="Alwajh">Alwajh</option>
                                </select>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                    Tour Code
                                </label>
                                <input
                                    type="text"
                                    value={editForm.code}
                                    onChange={(e) => setEditForm({ ...editForm, code: e.target.value })}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                    Update Document
                                </label>
                                <input
                                    type="file"
                                    onChange={(e) => setEditFile(e.target.files[0])}
                                    className="mt-1 block w-full text-sm text-gray-500 dark:text-gray-300
                                        file:mr-4 file:py-2 file:px-4
                                        file:rounded-md file:border-0
                                        file:text-sm file:font-semibold
                                        file:bg-blue-50 file:text-blue-700
                                        hover:file:bg-blue-100
                                        dark:file:bg-gray-600 dark:file:text-gray-200"
                                />
                            </div>
                            <div className="flex justify-end space-x-3">
                                <button
                                    type="button"
                                    onClick={() => setEditingTour(null)}
                                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    disabled={isEditingTour}
                                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
                                >
                                    {isEditingTour ? (
                                        <div className="flex items-center space-x-2">
                                            <LoadingSpinner />
                                            <span>Updating...</span>
                                        </div>
                                    ) : (
                                        'Update Tour'
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-8 text-gray-900 dark:text-white">
                Shore Excursions Pricing Administration
            </h1>

            <Tab.Group>
                <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-8">
                    <Tab
                        className={({ selected }) =>
                            classNames(
                                'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                selected
                                    ? 'bg-white text-blue-700 shadow'
                                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                            )
                        }
                    >
                        Assign Vendors
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            classNames(
                                'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                selected
                                    ? 'bg-white text-blue-700 shadow'
                                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                            )
                        }
                    >
                        View Submissions
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            classNames(
                                'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                selected
                                    ? 'bg-white text-blue-700 shadow'
                                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                            )
                        }
                    >
                        Manage Bidding
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            classNames(
                                'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                selected
                                    ? 'bg-white text-blue-700 shadow'
                                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                            )
                        }
                    >
                        Manage Tours
                    </Tab>
                </Tab.List>

                <Tab.Panels>
                    {/* Assign Vendors Panel */}
                    <Tab.Panel className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            <div>
                                <div className="flex justify-between items-center mb-4">
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                        Select Vendors
                                    </h3>
                                    <div className="flex items-center space-x-4">
                                        <label className="flex items-center space-x-2 text-sm text-gray-600 dark:text-gray-300 cursor-pointer">
                                            <input
                                                type="checkbox"
                                                checked={selectedVendors.length === vendors.length}
                                                onChange={handleSelectAllVendors}
                                                className="form-checkbox h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                                            />
                                            <span>Select All</span>
                                        </label>
                                        <button
                                            onClick={clearVendors}
                                            className="px-2 py-1 text-sm text-gray-600 dark:text-gray-300 hover:text-red-600 dark:hover:text-red-400 transition-colors"
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </div>
                                <div className="relative">
                                    {vendorsLoading ? (
                                        <div className="flex justify-center items-center h-60">
                                            <LoadingSpinner />
                                        </div>
                                    ) : (
                                        <div className="max-h-60 overflow-y-auto border border-gray-300 dark:border-gray-600 rounded-lg">
                                            {vendors.map(vendor => (
                                                <label
                                                    key={vendor.id}
                                                    className="flex items-center space-x-3 p-2 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedVendors.includes(vendor.email)}
                                                        onChange={() => handleVendorSelection(vendor)}
                                                        className="form-checkbox h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                                                    />
                                                    <span className="text-gray-700 dark:text-gray-300">
                                                        {vendor.name} ({vendor.email})
                                                    </span>
                                                </label>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div>
                                <div className="flex justify-between items-center mb-4">
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                        Select Tours
                                    </h3>
                                    <div className="flex items-center space-x-4">
                                        <label className="flex items-center space-x-2 text-sm text-gray-600 dark:text-gray-300 cursor-pointer">
                                            <input
                                                type="checkbox"
                                                checked={selectedTours.length === tours.length}
                                                onChange={handleSelectAllTours}
                                                className="form-checkbox h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                                            />
                                            <span>Select All</span>
                                        </label>
                                        <button
                                            onClick={clearTours}
                                            className="px-2 py-1 text-sm text-gray-600 dark:text-gray-300 hover:text-red-600 dark:hover:text-red-400 transition-colors"
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </div>
                                <div className="relative">
                                    {toursLoading ? (
                                        <div className="flex justify-center items-center h-60">
                                            <LoadingSpinner />
                                        </div>
                                    ) : (
                                        <div className="max-h-60 overflow-y-auto border border-gray-300 dark:border-gray-600 rounded-lg">
                                            {tours.map(tour => (
                                                <label
                                                    key={tour.id}
                                                    className="flex items-center space-x-3 p-2 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedTours.includes(tour.id)}
                                                        onChange={() => handleTourSelection(tour.id)}
                                                        className="form-checkbox h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                                                    />
                                                    <span className="text-gray-700 dark:text-gray-300">
                                                        {tour.name} ({tour.port})
                                                    </span>
                                                </label>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 space-y-2">
                            <div className="bg-blue-50 dark:bg-blue-900/30 p-4 rounded-lg">
                                <h4 className="font-semibold text-gray-900 dark:text-white mb-2">Selected Items:</h4>
                                <div className="space-y-2">
                                    <div>
                                        <p className="text-sm text-gray-600 dark:text-gray-300">
                                            Vendors: {selectedVendors.length > 0
                                                ? vendors.filter(v => selectedVendors.includes(v.email)).map(v => v.name).join(', ')
                                                : 'None selected'}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gray-600 dark:text-gray-300">
                                            Tours: {selectedTours.length > 0
                                                ? tours.filter(t => selectedTours.includes(t.id)).map(t => t.name).join(', ')
                                                : 'None selected'}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <button
                                onClick={handleAssignVendors}
                                disabled={assignmentsLoading || selectedVendors.length === 0 || selectedTours.length === 0}
                                className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300 disabled:opacity-50"
                            >
                                {assignmentsLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                        <LoadingSpinner />
                                        <span>Assigning...</span>
                                    </div>
                                ) : (
                                    'Assign Vendors to Tours'
                                )}
                            </button>
                        </div>

                        {/* Adding Current Tour Assignments section here */}
                        <div className="mt-8">
                            <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
                                Current Tour Assignments
                            </h3>
                            <div className="bg-gray-50 dark:bg-gray-700 rounded-lg overflow-hidden">
                                {assignedVendorsLoading ? (
                                    <div className="flex justify-center items-center h-40">
                                        <LoadingSpinner />
                                    </div>
                                ) : (
                                    Object.entries(
                                        assignments.reduce((acc, assignment) => {
                                            if (!acc[assignment.port]) {
                                                acc[assignment.port] = [];
                                            }
                                            acc[assignment.port].push(assignment);
                                            return acc;
                                        }, {})
                                    ).map(([port, portAssignments]) => (
                                        <div key={port} className="border-b border-gray-200 dark:border-gray-600 last:border-b-0">
                                            <button
                                                onClick={() => togglePortExpanded(port)}
                                                className="w-full bg-gray-100 dark:bg-gray-600 px-6 py-3 flex items-center justify-between text-lg font-semibold text-gray-900 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-500 transition-colors"
                                            >
                                                <div className="flex items-center space-x-2">
                                                    <span>{port}</span>
                                                    <span className="text-sm text-gray-500 dark:text-gray-400">
                                                        ({portAssignments.length} tours)
                                                    </span>
                                                </div>
                                                <svg
                                                    className={`w-5 h-5 transform transition-transform ${expandedPorts.has(port) ? 'rotate-180' : ''}`}
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                                </svg>
                                            </button>
                                            {expandedPorts.has(port) && (
                                                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                                                    <thead className="bg-gray-50 dark:bg-gray-700">
                                                        <tr>
                                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                                                Tour Code
                                                            </th>
                                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                                                Tour Name
                                                            </th>
                                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                                                Assigned Vendors
                                                            </th>
                                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                                                Actions
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                                                        {portAssignments.map((assignment) => (
                                                            <>
                                                                <tr key={assignment.id} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                                                                        {assignment.code}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                                                                        {assignment.tourName}
                                                                    </td>
                                                                    <td className="px-6 py-4 text-sm text-gray-900 dark:text-white">
                                                                        {assignment.assignedVendors.length} Vendors
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                                                                        <button
                                                                            onClick={() => toggleExpanded(assignment.id)}
                                                                            className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
                                                                        >
                                                                            <div className="flex items-center space-x-2">
                                                                                <span>{expandedAssignments.has(assignment.id) ? 'Hide' : 'Show'} Details</span>
                                                                                <svg
                                                                                    className={`w-5 h-5 transform transition-transform ${expandedAssignments.has(assignment.id) ? 'rotate-180' : ''}`}
                                                                                    fill="none"
                                                                                    stroke="currentColor"
                                                                                    viewBox="0 0 24 24"
                                                                                >
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                                                                </svg>
                                                                            </div>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                {expandedAssignments.has(assignment.id) && (
                                                                    <tr>
                                                                        <td colSpan="4" className="px-6 py-4 bg-gray-50 dark:bg-gray-700">
                                                                            <div className="space-y-2">
                                                                                {assignment.assignedVendors.map((vendor) => (
                                                                                    <div
                                                                                        key={vendor.id}
                                                                                        className="flex items-center justify-between p-3 bg-white dark:bg-gray-600 rounded-lg shadow-sm"
                                                                                    >
                                                                                        <div className="flex items-center space-x-4">
                                                                                            <span className="text-gray-900 dark:text-white">
                                                                                                {vendors.find(v => v.email === vendor.email)?.name || vendor.email}
                                                                                            </span>
                                                                                            {vendor.submitted && (
                                                                                                <span className="px-2 py-1 text-xs font-medium bg-green-100 text-green-800 rounded-full">
                                                                                                    Submitted
                                                                                                </span>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="flex items-center space-x-2">
                                                                                            <button
                                                                                                onClick={() => handleRemoveVendorFromAll(vendor)}
                                                                                                disabled={vendor.submitted || bulkDeletingVendor === vendor.email}
                                                                                                className={`p-1 rounded-md text-sm ${vendor.submitted || bulkDeletingVendor === vendor.email
                                                                                                    ? 'text-gray-400 cursor-not-allowed'
                                                                                                    : 'text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300 hover:bg-red-50 dark:hover:bg-red-900/20'
                                                                                                    }`}
                                                                                                title={vendor.submitted ? 'Cannot remove vendor who has submitted bids' : 'Remove from all tours'}
                                                                                            >
                                                                                                {bulkDeletingVendor === vendor.email ? (
                                                                                                    <div className="flex items-center space-x-1">
                                                                                                        <svg className="w-4 h-4 animate-spin" viewBox="0 0 24 24">
                                                                                                            <circle
                                                                                                                className="opacity-25"
                                                                                                                cx="12"
                                                                                                                cy="12"
                                                                                                                r="10"
                                                                                                                stroke="currentColor"
                                                                                                                strokeWidth="4"
                                                                                                                fill="none"
                                                                                                            />
                                                                                                            <path
                                                                                                                className="opacity-75"
                                                                                                                fill="currentColor"
                                                                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                                                            />
                                                                                                        </svg>
                                                                                                        <span>Removing...</span>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div className="flex items-center space-x-1">
                                                                                                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                                                        </svg>
                                                                                                        <span>Remove All</span>
                                                                                                    </div>
                                                                                                )}
                                                                                            </button>
                                                                                            <button
                                                                                                onClick={() => handleRemoveVendor(assignment.id, vendor)}
                                                                                                disabled={vendor.submitted || deletingVendors.has(vendor.email)}
                                                                                                className={`p-1 rounded-full ${vendor.submitted || deletingVendors.has(vendor.email)
                                                                                                    ? 'text-gray-400 cursor-not-allowed'
                                                                                                    : 'text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300 hover:bg-red-50 dark:hover:bg-red-900/20'
                                                                                                    }`}
                                                                                                title={vendor.submitted ? 'Cannot remove vendor who has submitted bids' : 'Remove from this tour'}
                                                                                            >
                                                                                                {deletingVendors.has(vendor.email) ? (
                                                                                                    <svg className="w-5 h-5 animate-spin" viewBox="0 0 24 24">
                                                                                                        <circle
                                                                                                            className="opacity-25"
                                                                                                            cx="12"
                                                                                                            cy="12"
                                                                                                            r="10"
                                                                                                            stroke="currentColor"
                                                                                                            strokeWidth="4"
                                                                                                            fill="none"
                                                                                                        />
                                                                                                        <path
                                                                                                            className="opacity-75"
                                                                                                            fill="currentColor"
                                                                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                                                        />
                                                                                                    </svg>
                                                                                                ) : (
                                                                                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                                                                    </svg>
                                                                                                )}
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </Tab.Panel>

                    {/* View Submissions Panel */}
                    <Tab.Panel className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
                        <div className="space-y-4">
                            <div className="flex justify-between items-center">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    Submitted Biddings
                                </h3>
                                <div className="flex items-center space-x-4">
                                    <span className="text-sm text-gray-600 dark:text-gray-400">
                                        Total Submissions: {submittedBiddings.length}
                                    </span>
                                </div>
                            </div>

                            {submittedBiddingsLoading ? (
                                <div className="flex justify-center items-center h-40">
                                    <LoadingSpinner />
                                </div>
                            ) : (
                                <div className="space-y-4">
                                    {Object.entries(
                                        submittedBiddings.reduce((acc, bidding) => {
                                            if (!acc[bidding.port]) {
                                                acc[bidding.port] = [];
                                            }
                                            acc[bidding.port].push(bidding);
                                            return acc;
                                        }, {})
                                    ).map(([port, portBiddings]) => (
                                        <div key={port} className="bg-gray-50 dark:bg-gray-700 rounded-lg overflow-hidden">
                                            <button
                                                onClick={() => togglePortExpanded(port)}
                                                className="w-full bg-gray-100 dark:bg-gray-600 px-4 py-3 flex items-center justify-between hover:bg-gray-200 dark:hover:bg-gray-500 transition-colors"
                                            >
                                                <h4 className="text-lg font-medium text-gray-900 dark:text-white flex items-center space-x-2">
                                                    <span>{port}</span>
                                                    <span className="text-sm text-gray-500 dark:text-gray-400">
                                                        ({portBiddings.length} submissions)
                                                    </span>
                                                </h4>
                                                <svg
                                                    className={`w-5 h-5 transform transition-transform ${expandedPorts.has(port) ? 'rotate-180' : ''
                                                        }`}
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M19 9l-7 7-7-7"
                                                    />
                                                </svg>
                                            </button>
                                            {expandedPorts.has(port) && (
                                                <div className="overflow-x-auto">
                                                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                                        <thead className="bg-gray-50 dark:bg-gray-700">
                                                            <tr>
                                                                <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                                                    Tour Code
                                                                </th>
                                                                <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                                                    Vendor
                                                                </th>
                                                                <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                                                    Round & Season
                                                                </th>
                                                                <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                                                    Pricing Tiers
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                                                            {portBiddings.map((bidding, index) => (
                                                                <tr key={`${bidding.tourId}_${bidding.vendor}_${index}`}
                                                                    className="hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors">
                                                                    <td className="px-4 py-4">
                                                                        <span className="text-sm font-medium text-gray-900 dark:text-white">
                                                                            {bidding.code}
                                                                        </span>
                                                                    </td>
                                                                    <td className="px-4 py-4">
                                                                        <div className="text-sm text-gray-900 dark:text-white">
                                                                            {bidding.vendor}
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-4 py-4">
                                                                        <div className="flex flex-col">
                                                                            <span className="text-sm text-gray-900 dark:text-white">
                                                                                Round {bidding.biddingRound}
                                                                            </span>
                                                                            <span className="text-sm text-gray-500 dark:text-gray-400">
                                                                                {bidding.season}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-4 py-4">
                                                                        <div className="flex flex-wrap gap-2">
                                                                            {bidding.tiers.sort((a, b) => a.tierId - b.tierId).map((tier, tierIndex) => (
                                                                                <div
                                                                                    key={tier.tierId}
                                                                                    className="inline-flex items-center bg-gray-100 dark:bg-gray-600 rounded-lg px-3 py-1"
                                                                                >
                                                                                    <div className="flex items-center space-x-3">
                                                                                        <span className="text-xs font-medium text-gray-700 dark:text-gray-300">
                                                                                            {tier.tier.split(' - ')[0]}
                                                                                        </span>
                                                                                        <div className="flex items-center space-x-2 text-xs text-gray-600 dark:text-gray-400">
                                                                                            <span>A:{tier.pricing.adultRate} SAR</span>
                                                                                            <span>C:{tier.pricing.childRate} SAR</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </Tab.Panel>

                    {/* Manage Bidding Panel */}
                    <Tab.Panel className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
                        <div className="space-y-6">
                            <div className="bg-blue-50 dark:bg-blue-900 p-4 rounded-lg">
                                <h3 className="text-lg font-semibold text-blue-900 dark:text-blue-100 mb-2">
                                    Current Bidding Status
                                </h3>
                                {biddingStatusLoading ? (
                                    <LoadingSpinner />
                                ) : (
                                    <p className="text-blue-800 dark:text-blue-200">
                                        Bidding is currently {biddingStatus}
                                    </p>
                                )}
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
                                    <h4 className="font-semibold text-gray-900 dark:text-white mb-2">
                                        Participating Vendors
                                    </h4>
                                    <p className="text-gray-600 dark:text-gray-300">
                                        {vendors.length} vendors active
                                    </p>
                                </div>

                                <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
                                    <h4 className="font-semibold text-gray-900 dark:text-white mb-2">
                                        Submission Status
                                    </h4>
                                    <p className="text-gray-600 dark:text-gray-300">
                                        {submissions.length}/{vendors.length} vendors submitted
                                    </p>
                                </div>
                            </div>

                            <div className="flex space-x-4">
                                <button
                                    onClick={startBidding}
                                    disabled={biddingActionLoading || biddingStatus === 'Started'}
                                    className={`flex-1 py-2 px-4 rounded-lg text-white transition duration-300 ${biddingStatus === 'Started' || biddingActionLoading
                                        ? 'bg-gray-400 cursor-not-allowed'
                                        : 'bg-blue-600 hover:bg-blue-700'
                                        }`}
                                >
                                    {biddingActionLoading ? <LoadingSpinner /> : 'Start Bidding'}
                                </button>
                                <button
                                    onClick={closeBidding}
                                    disabled={biddingActionLoading || biddingStatus === 'Closed'}
                                    className={`flex-1 py-2 px-4 rounded-lg text-white transition duration-300 ${biddingStatus === 'Closed' || biddingActionLoading
                                        ? 'bg-gray-400 cursor-not-allowed'
                                        : 'bg-red-600 hover:bg-red-700'
                                        }`}
                                >
                                    {biddingActionLoading ? <LoadingSpinner /> : 'Close Bidding'}
                                </button>
                            </div>
                        </div>
                    </Tab.Panel>

                    {/* Add new Tours Management Panel */}
                    <Tab.Panel className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
                        <div className="space-y-6">
                            {/* Add Tour Form */}
                            <div className="bg-gray-50 dark:bg-gray-700 p-6 rounded-lg">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                                    Add New Tour
                                </h3>
                                <form onSubmit={handleAddTour} className="space-y-4">
                                    <div>
                                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                            Tour Name
                                        </label>
                                        <input
                                            type="text"
                                            id="title"
                                            name="title"
                                            value={newTour.title}
                                            onChange={(e) => setNewTour({ ...newTour, title: e.target.value })}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="port" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                            Port
                                        </label>
                                        <select
                                            id="port"
                                            name="port"
                                            value={newTour.port}
                                            onChange={(e) => setNewTour({ ...newTour, port: e.target.value })}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                                            required
                                        >
                                            <option value="Jeddah">Jeddah</option>
                                            <option value="Dammam">Dammam</option>
                                            <option value="Yanbu">Yanbu</option>
                                            <option value="Alwajh">Alwajh</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="code" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                            Tour Code
                                        </label>
                                        <input
                                            type="text"
                                            id="code"
                                            name="code"
                                            value={newTour.code}
                                            onChange={(e) => setNewTour({ ...newTour, code: e.target.value })}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="document" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                            Tour Document
                                        </label>
                                        <input
                                            type="file"
                                            id="document"
                                            name="document"
                                            onChange={handleFileChange}
                                            className="mt-1 block w-full text-sm text-gray-500 dark:text-gray-300
                                                file:mr-4 file:py-2 file:px-4
                                                file:rounded-md file:border-0
                                                file:text-sm file:font-semibold
                                                file:bg-blue-50 file:text-blue-700
                                                hover:file:bg-blue-100
                                                dark:file:bg-gray-600 dark:file:text-gray-200"
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        disabled={isAddingTour}
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                                    >
                                        {isAddingTour ? (
                                            <div className="flex items-center space-x-2">
                                                <LoadingSpinner />
                                                <span>Adding Tour...</span>
                                            </div>
                                        ) : (
                                            'Add Tour'
                                        )}
                                    </button>
                                </form>
                            </div>

                            {/* Existing Tours List */}
                            <div className="bg-gray-50 dark:bg-gray-700 p-6 rounded-lg">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                                    Existing Tours
                                </h3>
                                <div className="overflow-x-auto">
                                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                                        <thead className="bg-gray-50 dark:bg-gray-700">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                                    Tour Code
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                                    Title
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                                    Port
                                                </th>
                                                {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                                    Document
                                                </th> */}
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                                            {tours.map((tour) => (
                                                <tr key={tour.id} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                                                        {tour.code}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                                                        {tour.name}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                                                        {tour.port}
                                                    </td>
                                                    {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                                                        {tour.documentName && (
                                                            <a
                                                                href={`/api/v1/tours/${tour.id}/document`}
                                                                className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {tour.documentName}
                                                            </a>
                                                        )}
                                                    </td> */}
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                                                        <div className="flex space-x-2">
                                                            <button
                                                                onClick={() => handleEditTour(tour)}
                                                                className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
                                                            >
                                                                Edit
                                                            </button>
                                                            {/* <button
                                                                onClick={() => handleDeleteTour(tour.id)}
                                                                className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                                                            >
                                                                Delete
                                                            </button> */}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>

            {/* Add Confirmation Modals */}
            {showConfirmation && <ConfirmationModal />}
            {removeAllConfirmation.show && <RemoveAllConfirmationModal />}
            {editingTour && <EditTourModal />}
        </div>
    );
}

export default ShoreExcursionAdmin; 